<template>
  <div class="mx-3 mx-md-6 mb-6">
    <v-dialog v-model="dialogDelete" max-width="530px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to detach this branch?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialogDelete = false; branch=''"
            >Cancel</v-btn
          >
          <v-btn
            color="primary darken-1"
            text
            @click="detachBranch"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mt-6">
      <v-col cols="12" md="6" class="pb-0">
        <div class="primary--text headline mb-2">Restaurant List</div>
        <!-- <div class="text-subtitle-2">
          Slab-wise branch information are provided here
        </div> -->
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end pb-0">
        <v-btn text class="" color="white" dark @click="onBack">
          <v-icon dark left> mdi-arrow-left </v-icon>Back
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="card mt-6 pa-6" v-for="(branch, i) in branches" :key="i">
      <v-row class="mb-0">
        <v-col cols="12" md="8" class="pb-0">
          <div class="text-h6">
            {{ branch.tenantInfo.name }}
          </div>
          <div class="grey--text my-2">
            <!-- <v-btn @click="onDetach" icon>
              <v-icon>mdi-crop-square</v-icon>
            </v-btn> -->
            
            {{ branch.branchInfo.name }}
          </div>
          <span class="text-body-1 grey--text mr-2">{{
            branch.slab_style
          }}</span>
          <span class="mr-2">|</span>
          <span class="text-body-1 grey--text"
            >Deafult {{ branch.default_percentage }}%</span
          >
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-center justify-end">
          <div>
            <div class="pa-0 text-right">
              <v-btn text color="primary" @click="onEditBranch(branch)" class="px-0">
                <v-icon small left>mdi-pencil</v-icon>
                <span class="text-button">Edit</span>
              </v-btn>
            </div>
            <v-btn color="#E57373" @click.stop="onDetach(branch)" text class="px-0">
              <v-icon>mdi-delete-outline</v-icon>
              <span class="text-button">Detach</span>
            </v-btn>
            <!-- <v-btn disabled text color="primary">
              <v-icon small left>mdi-history</v-icon>
              <span class="text-button">Log History</span>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>

      <div v-for="(item, j) in branch.slab" :key="j">
        <v-row class="my-0">
          <v-col cols="12" class="pt-5">
            <div class="text-subtitle-1 primary--text">
              Slab {{ j.slice(5) }}
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-body-2 grey--text mb-2">Starting Value</div>
            <div class="text-body-1 mb-0">{{ item.start }}</div>
          </v-col>
          <v-col cols="3">
            <div class="text-body-2 grey--text mb-2">Ending Value</div>
            <div class="text-body-1 mb-0">{{ item.end }}</div>
          </v-col>
          <v-col cols="3">
            <div class="text-body-2 grey--text mb-2">Dine-In</div>
            <div class="text-body-1 mb-0">{{ item.dine_in }}%</div>
          </v-col>
          <v-col cols="3">
            <div class="text-body-2 grey--text mb-2">Provider</div>
            <div class="text-body-1 mb-0">{{ item.takeway_provider }}%</div>
          </v-col>
        </v-row>
        <v-divider
          v-if="Object.keys(branch.slab).length != j.slice(5)"
          class="mt-2"
        ></v-divider>
      </div>
    </v-card>
    <v-card height="240" class="mt-6 card pa-6">
      <div class="mt-4">
        <v-img
          class="mx-auto"
          width="96"
          height="86"
          src="../../assets/attact-branch.png"
        ></v-img>
        <div class="text-center text-body-1 mt-4">
          Click on the button to attach new branch
        </div>
        <div class="d-flex justify-center mt-2">
          <v-btn @click="onAttach" text color="primary">
            <v-icon left>mdi-plus</v-icon>
            <span class="text-button">Attach New Branch</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from "../../main"
import store from '../../store'
export default {
  props: ["data"],
  data() {
    return {
      branches: "",
      fcId: "",
      dialogDelete: false,
      branch: ''
    };
  },
  created() {
    // console.log("getting daat in branch", this.data);
    // console.log("getting fc id from store", this.data.foodCourt.id);
    this.fcId = this.data.foodCourt.id;
    this.branches = this.data.branches;
  },
  methods: {
    onEditBranch(item) {
      // console.log('edit branch info', item)
      eventBus.$emit('editBranch', item)
    },
    onDetach(branch) {
      // console.log("detached", branch)
      this.branch= branch
      this.dialogDelete = true
    },
    detachBranch() {
      this.dialogDelete = false
      // console.log("detached api")
      const payload = {}
      payload["branch_id"] = this.branch.branch_id
      payload["tenant_id"] = this.branch.tenant_id
      // console.log(payload)
      store.dispatch('detachBranch', payload)
      .then((res) => {
        // console.log('after detach',res)
        eventBus.$emit('snackbar', {
          step2: true,
          snackbarColor: "#31A274",
          snackbarText: `${res.data.message}`,
        })
      }).catch((err) => {
        eventBus.$emit('snackbar', {
          snackbarColor: "#FF5252",
          snackbarText: "Something Went Wrong!",
        })
        console.log(err)
      });
    },
    onAttach() {
      eventBus.$emit("step2")
    },
    onBack() {
      eventBus.$emit("backToList")
    },
  },
};
</script>
